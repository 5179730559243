.step {
  position: relative;
  padding-right: 20px;
  min-height: 48px;
  color: gray;
}

.title {
  font-size: 1rem;
  line-height: 1.5em;
  font-family: 'SF Pro Display Medium';
}
.caption {
  font-size: 0.8em;
}

.step + .step {
  margin-top: 1.5em;
}

.step > div:first-child {
  position: static;
  height: 0;
}

.step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}

.circle {
  text-align: center;
  font-size: 14px;
  font-family: 'SF Pro Display Semibold';
  background: white;
  border: 1px solid #78ae64;
  color: #78ae64;
  position: absolute;
  right: 0;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.2rem;
  border-radius: 100%;
  text-align: center;
}

.circle:after {
  content: ' ';
  position: absolute;
  display: block;
  top: -10px;
  right: 50%;
  bottom: 0px;
  left: 48%;
  height: 28px;
  width: 2px;
  transform: scale(1, 2);
  transform-origin: 48% -100%;
  background-color: #78ae64;
  z-index: -1;
}

.step:last-child .circle:after {
  display: none;
}

.step.step-highlight {
  color: #000000;
}

.step.step-highlight .circle {
  background-color: #78ae64;
  color: white;
}

.step-complete-check {
  margin: 6px auto 0 auto;
}
