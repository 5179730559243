.select-input {
  background-color: transparent;
  font-size: 16px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url('assets/images/down-chevron-gray-icon.svg');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 7px;
  padding-right: 1.5rem;
}
