.info-tooltip {
  z-index: 100;
  background: #527acc;
  color: white;
  border-radius: 12px;
  font: 'SF Pro Display Medium';
  font-size: 14px;
  padding: 15px 20px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 24px;
  height: 24px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.info-tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -10px;
}

.info-tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -10px;
}

.info-tooltip[data-popper-placement^='left'] > #arrow {
  right: -10px;
}

.info-tooltip[data-popper-placement^='right'] > #arrow {
  left: -10px;
}
