@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'SF Pro Display Light';
    src: local('SF Pro Display Light'),
      url('./fonts/SFProDisplay-Light.ttf') format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'SF Pro Display Regular';
    src: local('SF Pro Display Regular'),
      url('./fonts/SFProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'SF Pro Display Medium';
    src: local('SF Pro Display Medium'),
      url('./fonts/SFProDisplay-Medium.ttf') format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'SF Pro Display Semibold';
    src: local('SF Pro Display Semibold'),
      url('./fonts/SFProDisplay-Semibold.ttf') format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Lato Bold';
    src: local('Lato Bold'), url('./fonts/Lato-Bold.ttf') format('truetype');
    font-weight: normal;
  }
}

@layer components {
  .btn-green {
    @apply rounded bg-tnf-green text-white px-8 py-2.5 text-center;
  }

  .btn-green-outline {
    @apply rounded border border-tnf-green text-tnf-green px-8 py-2.5 text-center;
  }

  .subheading-sm {
    @apply font-sf-pro-semibold text-gray-600 mt-2 w-80 leading-6;
  }

  .blue-heading-light {
    @apply font-sf-pro-light text-blue-400 text-4.5xl;
  }

  .input-label {
    @apply text-gray-500 text-sm;
  }

  /* Card */
  .card {
    @apply flex flex-col rounded-md bg-emerald-100 pt-4 relative;
  }

  .card-header {
    @apply font-sf-pro-light text-4xl text-white pb-4;
  }

  .card-body {
    @apply px-2.5 text-gray-800 font-sf-pro-light text-sm;
  }

  .card-footer {
    @apply bg-gray-600 text-white rounded-b-md px-3 py-2 font-sf-pro-medium text-xs w-full;
  }

  /* Button group */
  .btn-group {
    @apply rounded justify-items-center w-60 text-center font-sf-pro-medium text-sm;
  }

  .btn-group > button {
    @apply w-6/12 py-3.5;
  }

  .radio-text {
    @apply text-base font-sf-pro-medium;
  }

  .circular-btn-action-wrapper {
    @apply w-12 h-12;
  }

  .input-width {
    @apply lg:w-64 xl:w-80 2xl:w-96;
  }

  .circle-badge {
    @apply w-6 h-6 rounded-full bg-tnf-green-400 text-white justify-center items-center font-sf-pro-semibold pt-0.5;
  }
}

/* Subtracts appbar height */
.h-screen {
  height: calc(100vh - 9rem) !important;
}

.h-screen-full {
  height: 100vh !important;
}

body {
  margin: 0;
  font-family: 'SF Pro Display Regular', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Remove default button styles.
 */
button {
  background-color: transparent;
  color: inherit;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: inherit;
}

button:not(:disabled) {
  cursor: pointer;
}

.detail-text {
  font-family: 'SF Pro Display Medium';
  font-size: 1rem;
  color: #4b4b4b;
}

.two-col-list {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .page-break-after-print {
    page-break-after: always;
  }

  body {
    -webkit-print-color-adjust: exact;
  }
}
