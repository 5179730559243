@layer components {
  .activity-form-col {
    @apply md:w-56 lg:w-6/12 xl:w-6/12 2xl:w-9/12;
  }
}
.activity-form-col {
  flex-basis: 100%;
  flex: 1;
}
.activity-form-row > :first-child {
  margin-right: 3.75rem;
}
