.toggle:checked ~ .dot {
  transform: translateX(100%);
  background-color: #78ae64;
}

.toggle-label {
  color: #585858;
}

.toggle-inner {
  height: 12px;
  width: 48px;
  background-color: #c4e6b8;
}
