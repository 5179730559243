.card-footer-col {
  flex: 1;
  text-align: center;
}

.card-footer-col:not(:last-child) {
  border-right: 1px solid #333333;
}

.card-footer-col-devider {
  margin: 0 auto;
  background-color: #333333;
  width: 70%;
  border: none;
  height: 1px;
}

.card-footer-col > .card-footer-col-devider:last-child {
  display: none;
}

.badge-row > div:not(:first-child) {
  margin-left: 0.75rem;
}
