.list-group-nav > li:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.list-group-nav > li:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.list-group-nav > li {
  @apply px-4 py-3 my-px text-white text-base font-sf-pro-medium cursor-pointer z-50 bg-tnf-green;
}

.list-group-nav .active {
  background-color: #385c24;
}
