.radio-label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide default radio button */
.radio-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* -- Checkmark shared styles -- */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-label input:checked ~ .checkmark:after {
  display: block;
}

.checkmark {
  border: 1px solid #78ae64;
  position: absolute;
  top: 0;
  left: 0;
}

.radio-label .checkmark:after {
  border-radius: 50%;
  background: #78ae64;
}

/* -- End of Checkmark shared styles -- */

/* Medium sized checkmark */
.checkmark-md {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.radio-label .checkmark-md:after {
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
}

/* Small checkmark */
.checkmark-sm {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.radio-label .checkmark-sm:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
}
