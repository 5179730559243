.dropdown-li:not(:first-child)::after {
  content: '';
  position: absolute;
  height: 1px;
  background-color: #e6e6e6;
  width: calc(100% - 2rem);
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
